const migrations = [];

export const registerMigration = (migration) => migrations.push(migration);

export default (state, store) => {
  let newState = state
    ? {
        migrations: {},
        ...state,
      }
    : {
        migrations: Object.fromEntries(migrations.map((m, id) => [id, true])),
      };

  const asyncMigrations = [];

  migrations.forEach((migration, idx) => {
    if (newState.migrations[idx]) return;
    const result = migration.migrate(newState, store);
    if (typeof result.then !== 'function') {
      newState = result;
      newState.migrations[idx] = true;
    } else asyncMigrations.push({ promise: result, idx });
  });

  asyncMigrations.forEach(async ({ promise, idx }) => {
    await promise;
    store.commit('markMigrationAsApplied', idx);
  });

  return newState;
};
